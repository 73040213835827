// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import './assets/scss/aim-variables.scss';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-base-frontend-primary: mat.define-palette(mat.$indigo-palette);
$angular-base-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-base-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-base-frontend-theme: mat.define-light-theme((
  color: (
    primary: $angular-base-frontend-primary,
    accent: $angular-base-frontend-accent,
    warn: $angular-base-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-base-frontend-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* AIM Palette */
$orange: #F57C22;
$purple: #712772;
$lavender: #B36DAC;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; color: $purple }

.mat-typography h1 {
  font-size: 28px !important;
}

.mat-button-toggle-button:focus {
  outline: none;
}

.mat-button-toggle-focus-overlay {
  background-color: transparent;
  opacity: 0 !important;
}


.underline-thin {
  border-bottom: solid 3px #EBF2F5;
  width: 100%;
  margin-left: 0;
}

.menu-overlay {
  background: #000;
  opacity: 0;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  transition: all ease 0.4s;
  position: fixed;
  z-index: 1030;
}

.show-nav {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.show-nav .menu-overlay {
  opacity: 0.5;
  visibility: visible;
}

.background-image > * > .container {
  padding-top: 78px;
}

html,
body {
  background-size: cover;
  background: url('./assets/images/aim-background.png') bottom right no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.instructions {
  color: #79717b;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 50px;
  p {
    margin-bottom: 20px;
  }
  a {
    color: #F57C22;
    transition: all ease 0.4s;
  }
  a:hover {
    color: #712772;
    text-decoration: underline;
  }
}

.mat-radio-button .mat-radio-outer-circle{
  border-color: #EBF2F5;
  background-color: #EBF2F5;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: #EBF2F5;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color: #B36DAC;
  background-color: #B36DAC;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color:rgb(255, 37, 37,.26)
}

.mat-dialog-container {
  padding: 0px !important;
  overflow: hidden !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background-color: #B26DAC;
  opacity: .5 !important;
}

.mat-select-value-text{
  color: #B26DAC;
}

::-webkit-input-placeholder{opacity: .4}
:-ms-input-placeholder{opacity: .4}

.mat-chip-list-wrapper .mat-standard-chip{
  background-color: #EBF2F5;
  color: #B26DAC;
}

.mat-chip-list-wrapper{
  justify-content: center;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-legacy input{
  caret-color: #B36DAC;
  color: #B36DAC;
}

.mat-form-field-appearance-legacy .mat-form-field-underline{
  padding-bottom: 0px;
  border: none;
  background-color: transparent;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper{
  padding-bottom: 0px;
  border-bottom: solid 4px #EBF2F5;
  background-color: transparent;
}

.mat-form-field-appearance-legacy .mat-option-text{
  color: #B36DAC;
}

.mat-select-placeholder{
  color: #a9a9a9;
  opacity: .6;
}

.mat-select-trigger {
  padding: 13px 13px 13px 20px !important;
}

.mat-expansion-panel-header {
  padding: 13px 20px !important;
}

/* Styles to make Footer appear at the bottom of the page */
.app, .background-image {
  height: 100%;
}

app-footer {
  margin-top: auto;
}

app-pilot-sites, app-pilot-site, app-users, app-new-user, app-new-partner, app-partner-organization, app-reporting, app-measures-dashboard, app-questionnaire-selection, app-questionnaire-landing {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  position: absolute;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset!important;
}

/* SUPPORT REQUESTS */
mat-dialog-container, .mat-dialog-container {
  border-radius: 12px !important;
}

.support-dialog {
  border-radius: 12px;
}

.support-header{
  background-color: #712772;
  color: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 1px;
  position:sticky;
  top: 0;
}

.support-container{
  background-color: #FFF;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px;

  input {
    color: #B36DAC;
    border: none;
    background-color: #EBF2F5 !important;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    padding: 13px 13px 13px 20px;
    border-radius: 8px;
    transition: all ease 0.4s;
    border-bottom: 0 !important;
  }

  input:hover {
    background-color: #DCE3E5 !important;
  }

  input:focus {
    background-color: #DCE3E5 !important;
    outline: 1.5px solid #B36DAC;
    transition: none;
  }
}

.support-input-row{
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;

  > div:last-child {
    background-color: white;
    border-radius: 8px;
    font-size: 16px;
  }
}

.message-bubble {
  i {
    opacity: 0.5;
  }
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-wrapper{
  border-bottom: none !important;
}

.support-input{
  width: 80%;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  border: 0;
  padding: 0;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  border-bottom: 0 !important;
}

.mat-form-field-label-wrapper {
  top: 0;
  bottom: 0;
  margin: auto;
}

.submit-button{
  width: 20%;
  background-color: #F57C22;
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all ease 0.4s;
}

.submit-button:hover {
  background-color: #712772;
}



.support-message{
  width: 40%;
  background: white;
  border-radius: 8px;
}

.button-support {
  background-color: #F57C22;
  border: 2px solid #F57C22;
  border-radius: 6px;
  color: #FFF;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 20px 7px;
  display: inline-block;
  text-align: center;
  transition: all ease 0.4s;
  cursor: pointer;
}

.button-support:hover {
  background-color: #712772;
  border: 2px solid #712772;
  color: #FFF;
  text-decoration: none;
}

/* MODAL FIXES */

.cdk-overlay-container {
  z-index: 1030;
}

.cdk-overlay-pane {
  max-height: 90% !important;
}

.pdf-container-dialog {
  height: 90vh !important;
}

/* MEASURE TABS */

app-measures-dashboard {

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 18px !important;
  }

  .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle, .mat-button-toggle-checked, .type-card {
    min-height: 60px;
    display: flex;
  }

}

/* MEASURES QUESTION DIALOG */

app-question-dialog {
  max-height: 800px;
  height: 80%;
  display: block;
  overflow-y: scroll
}

/* INFORMATION SETS LAST ROW BOTTOM BORDER RADIUS */

.information-set-list{
  app-information-set:last-child .information-set-row {
    border-radius: 0 0 10px 10px;
  }
}

/* INFORMATION SETS CHIP LIST */

.mat-chip-list-wrapper {
  justify-content: flex-start;
}


/* MEDIA QUERIES */

@media screen and (min-width: 1200px) {

  .menu-overlay {
    display: none;
  }

}
